<template>
  <v-container fluid style="margin: 0; padding: 0">
    <div class="mb-1" style="background-color: white">
      <select-accounts></select-accounts>
    </div>

    <!-- router view -->
    <router-view></router-view>
  </v-container>
</template>

<script>
import SelectAccounts from "../../components/GroupComponents/Account/SelectAccounts.vue";
export default {
  components: {
    SelectAccounts,
  },
};
</script>

<style></style>
