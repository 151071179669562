<template>
  <v-row dense>
    <v-col cols="12" sm="6">
      <v-autocomplete
        v-model="selectedAccounts"
        :items="activeAccounts"
        clearable
        item-value="_id"
        item-text="name"
        label="Valitse näytettävät tilit"
        dense
        outlined
        multiple
        small-chips
        deletable-chips
        hide-details
        :search-input.sync="searchInput1"
        @change="
          selectedGroups = [];
          searchInput1 = '';
        "
      >
      </v-autocomplete>
    </v-col>

    <v-col cols="12" sm="6">
      <v-autocomplete
        v-model="selectedGroups"
        :items="activeGroups"
        clearable
        item-value="_id"
        item-text="name"
        label="Valitse näytettävät ryhmät"
        dense
        outlined
        multiple
        small-chips
        deletable-chips
        hide-details
        :search-input.sync="searchInput2"
        @change="
          selectedAccounts = [];
          searchInput2 = '';
        "
        no-filter
        @input.native="getGroups"
      >
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { debounce } from "../../../utils/helpers";

export default {
  created() {
    this.getActiveAccounts();
  },

  data() {
    return {
      searchInput1: "",
      searchInput2: "",
      groupInput: null,
    };
  },

  computed: {
    ...mapState("account", ["activeAccounts", "activeGroups"]),

    selectedAccounts: {
      get() {
        return this.$store.state.account.selectedAccounts;
      },
      set(arr) {
        this.setSelectedAccounts(arr);
      },
    },

    selectedGroups: {
      get() {
        return this.$store.state.account.selectedGroups;
      },
      set(arr) {
        this.setSelectedGroups(arr);
      },
    },
  },

  watch: {
    groupInput: debounce(function (newVal) {
      this.searchGroups(`/api/v1/group/search?search=${newVal}`);
    }, 1000),
  },

  methods: {
    ...mapActions("account", ["getActiveAccounts", "searchGroups"]),
    ...mapMutations("account", ["setSelectedAccounts", "setSelectedGroups"]),

    getGroups(event) {
      if (event.target.value != "*") this.groupInput = event.target.value;
    },
  },
};
</script>

<style></style>
